import React, { useState, useContext, useEffect, useRef } from "react";
import {
  LoadingOverlay,
  Text,
  Button,
  Group as MantineGroup,
  Stack,
  Center,
  ThemeIcon,
  Box,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconShieldCheck } from "@tabler/icons-react";

import { DataContext } from "../context/DataContext";
import useStatusCheck from "../hooks/useStatusCheck";
import useAdjustInputSize from "../hooks/useAdjustInputSize";
import FloatingLabelInput from "./floating-label-input";
import DateOfBirthSelect from "./date-of-birth-select";
import AddressInput from "./address-input";

const DataCollectionForm = ({
  title,
  subtitle,
  icon,
  componentKey,
  endpoint,
  config,
  buttonText,
  reAttempts = 0,
  onApiResponse,
}) => {
  const { data, text, dispatch } = useContext(DataContext);
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState(false); // Controls when status check is active
  const [attemptsLeft, setAttemptsLeft] = useState(reAttempts);
  const [statusResponse, setStatusResponse] = useState(null); // Store latest status check response
  const lastConfirmationRef = useRef(null); // Track the last confirmation number
  const confirmationsReceivedRef = useRef([]); // Track all confirmation numbers
  const totalAttemptsUsedRef = useRef(0); // Track how many attempts we've used
  const inputSize = useAdjustInputSize();

  // Track what attempt we're on
  const [currentAttempt, setCurrentAttempt] = useState(0); // Start at 0 to indicate initial check
  const [hasShownFormForCurrentAttempt, setHasShownFormForCurrentAttempt] = useState(false);
  // Create ref to track when we're waiting for confirmation after submission
  const waitingForConfirmationChangeRef = useRef(false);
  const initialLoadRef = useRef(true); // Track if this is the initial load
  
  // Custom status check handler that can stop polling
  const handleStatusResult = (statusResult, confirmationChanged) => {
    // First initial status back means we can stop the initial load flag
    if (initialLoadRef.current && statusResult) {
      initialLoadRef.current = false;
    }
    
    // Store the response
    setStatusResponse(statusResult);
    
    // Get the confirmation from the response
    const confirmationNumber = statusResult.confirmation || 'unknown';
    
    // SPECIAL CASE: If we've never shown a form and get a FAIL status, show the form
    if (statusResult.status === "FAIL" && !hasShownFormForCurrentAttempt && initialLoadRef.current) {
      initialLoadRef.current = false;
      setCurrentAttempt(1);
      setHasShownFormForCurrentAttempt(true);
      setAttemptsLeft(reAttempts);
      setLoading(false);
      // Stop polling while form is shown - we'll restart when form is submitted
      setResult(false);
      return false; // Signal to show form
    }
    
    // CASE 1: PASS result - always handle immediately
    if (statusResult.status === "PASS") {
      try {
        dispatch({ type: "SET_STATUS", payload: "PASS" });
        dispatch({ type: "SET_IS_CURRENT_COMPONENT_COMPLETE", payload: true });
      } catch (err) {
        // Silent fail
      }
      setLoading(true);
      return true; // Continue with success flow
    }
    
    // CASE 2: Initial attempt (attempt 0 or first confirmation number detected)
    // This is the first time we're loading the component or we just got the first status
    if ((currentAttempt === 0 || currentAttempt === 1) && 
        statusResult.status === "FAIL" && 
        !hasShownFormForCurrentAttempt) {
      // Store the confirmation
      lastConfirmationRef.current = confirmationNumber;
      
      // Increment to attempt 1 (first real attempt) if still at 0
      if (currentAttempt === 0) {
        setCurrentAttempt(1);
      }
      setHasShownFormForCurrentAttempt(true);
      
      // Set attempts
      setAttemptsLeft(reAttempts);
      
      // Show the form
      setLoading(false); 
      // Stop polling while form is shown - we'll restart when form is submitted
      setResult(false);
      return false; // Signal to show form, hook will stop polling
    }
    
    // CASE 3: Waiting for confirmation change after submitting a form
    if (waitingForConfirmationChangeRef.current && confirmationChanged) {
      // Reset waiting flag since we've received the new confirmation
      waitingForConfirmationChangeRef.current = false;
      
      // Store the new confirmation
      lastConfirmationRef.current = confirmationNumber;
      confirmationsReceivedRef.current.push(confirmationNumber);
      
      // Update form shown flag for next attempt
      setHasShownFormForCurrentAttempt(false);
      
      // If it's a fail and we have attempts left, show the form
      if (statusResult.status !== "PASS" && attemptsLeft > 0) {
        setLoading(false);
        // Stop polling while form is shown - we'll restart when form is submitted
        setResult(false);
        return false; // Signal to show form, hook will stop polling
      }
      
      // If it's the last attempt or a PASS, show the final result
      if (statusResult.status !== "PASS" && attemptsLeft === 0) {
        try {
          dispatch({ type: "SET_STATUS", payload: "FAIL" });
          dispatch({ type: "SET_IS_CURRENT_COMPONENT_COMPLETE", payload: true });
        } catch (err) {
          // Silent fail
        }
      }
      
      setLoading(true);
      return true; // Continue with flow - polling will continue
    }
    
    // CASE 4: Waiting for confirmation change
    
    // Make sure we're in PENDING state while waiting
    try {
      dispatch({ type: "SET_STATUS", payload: "PENDING" });
      dispatch({ type: "SET_IS_CURRENT_COMPONENT_COMPLETE", payload: false });
    } catch (err) {
      // Silent fail
    }
    
    // CRITICAL: For COPPA flow - ensure loading state is maintained
    setLoading(true);
    
    // Ensure polling is active but at a controlled rate
    if (!result) {
      setResult(true); // Re-activate status check if it was stopped
    }
    
    // Check if we're still waiting for the first attempt's result
    // Skip this section since we want to wait for the API response and confirmation
    
    // THIS RETURN VALUE IS CRITICAL
    // Return true to ensure the hook continues polling while waiting
    return true; // This ensures the hook will keep polling
  };

  // Custom status check hook with our handler
  useStatusCheck(result, false, componentKey, handleStatusResult);

  const form = useForm({
    initialValues: config.fields.reduce((acc, field) => {
      if (field.type === "address") {
        acc["addr"] = data.addr || "";
        acc["city"] = data.city || "";
        acc["state"] = data.state || "";
        acc["zip"] = data.zip || "";
      } else {
        acc[field.name] = data[field.name] || field.initialValue || "";
      }
      return acc;
    }, {}),
    validate: (values) =>
      config.fields.reduce((acc, field) => {
        if (field.validation) {
          let error;

          // Check if the field is "address" and validate each part of the address
          if (field.name === "address" && field.type === "address") {
            // Validate each part of the address
            const addressForValidate = {
              addr: values.addr,
              city: values.city,
              state: values.state,
              zip: values.zip,
            };
            error = field.validation(addressForValidate);
            if (error) acc.address = error;
          } else {
            // Validate other fields
            error = field.validation(values[field.name]);
            if (error) {
              acc[field.name] = error;
            }
          }
        }
        return acc;
      }, {}),
  });

  const submitHandler = async () => {
    const errors = form.validate();
    
    if (!errors.hasErrors) {
      // Always decrement attempts left for each submission
      const newAttemptsLeft = attemptsLeft - 1;
      setAttemptsLeft(newAttemptsLeft);
      
      // Update data
      Object.keys(form.values).forEach((key) => {
        data[key] = form.values[key];
      });
      
      // Update settings
      if (!data.settings) data.settings = {};
      data.settings.currentAttempt = currentAttempt;
      data.settings.attemptsLeft = newAttemptsLeft;
      
      // Add attemptsLeft directly to data object for COPPA endpoint
      data.attemptsLeft = newAttemptsLeft;
      
      setLoading(true);

      try {
        // Special case for idmatch/coppa endpoint
        const apiUrl = endpoint === "/v3/stable/api/frontend/idmatchCoppa" 
          ? "https://vx.dcams.app/frontend/idmatch/coppa"
          : `${process.env.REACT_APP_BACKEND_URL}${endpoint}`;
        
        const response = await fetch(
          apiUrl,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(data),
          }
        )
          .then((res) => res.json())
          .then((responseData) => {
            if (typeof onApiResponse === "function") {
              onApiResponse(responseData);
            }
            
            // Force PENDING state
            try {
              dispatch({ type: "SET_STATUS", payload: "PENDING" });
              dispatch({ type: "SET_IS_CURRENT_COMPONENT_COMPLETE", payload: false });
            } catch (err) {
              // Silent fail
            }
            
            // Start the status check
            setLoading(true);
            
            // Increment attempt counter
            setCurrentAttempt(prevAttempt => prevAttempt + 1);
            
            // CRITICAL - track that we're waiting for a confirmation change
            waitingForConfirmationChangeRef.current = true;
            
            // Activate status check
            setResult(true);
          });
      } catch (error) {
        alert("Something Went Wrong! Please try again.");
      }
    }
  };

  useEffect(() => {
    if (attemptsLeft < reAttempts) {
      const errors = form.validate();
      const allFieldsInvalid = Object.keys(form.values).reduce((acc, key) => {
        if (key === "fn") {
          acc[key] = text.coppa.secondAttemptFirstNameError;
        }
        if (key === "ln") {
          acc[key] = text.coppa.secondAttemptLastNameError;
        }
        return acc;
      }, {});
      form.setErrors(allFieldsInvalid);
    }
  }, [attemptsLeft, reAttempts]);

  const handleDobChange = (dob) => {
    form.setFieldValue("dob", dob.year + dob.month + dob.day);
  };

  const handleAddressChange = (address) => {
    form.setFieldValue("addr", address.addr);
    form.setFieldValue("city", address.city);
    form.setFieldValue("state", address.state);
    form.setFieldValue("zip", address.zip);
  };

  // Initial load effect
  useEffect(() => {
    setCurrentAttempt(0); // Start at attempt 0 (initial data validation)
    setHasShownFormForCurrentAttempt(false); // Haven't shown the form yet
    
    // Initial setup of attemptsLeft - ensuring we start with the right value
    // Since we're starting with 2 attempts, we need to initialize this to 2
    setAttemptsLeft(reAttempts);
    
    // Make an initial check
    const errors = form.validate();
    if (!errors.hasErrors) {
      // Add this check to the data
      if (!data.settings) data.settings = {};
      data.settings.currentAttempt = 0;
      data.settings.totalAttempts = reAttempts + 1;
      
      // Initialize attemptsLeft in data for first submission
      // This should be 2 (reAttempts) to ensure the sequence is 2,1,0
      data.attemptsLeft = reAttempts;
      
      setLoading(true);
      setResult(true); // Start polling with status check
    } else {
      // Just show the form if there are errors
      setLoading(false);
      form.clearErrors();
    }
  }, []);

  if (loading) {
    return <LoadingOverlay visible />;
  }

  const groupedFields = config.fields.reduce(
    (acc, field) => {
      if (field.group) {
        if (!acc.grouped[field.group]) {
          acc.grouped[field.group] = [];
        }
        acc.grouped[field.group].push(field);
      } else {
        acc.ungrouped.push(field);
      }
      return acc;
    },
    { grouped: {}, ungrouped: [] }
  );

  return (
    <form
      onSubmit={form.onSubmit(submitHandler)}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <Box>
        <Stack spacing={8} mt={inputSize === "md" ? 5 : 5}>
          <Center>
            <ThemeIcon
              variant="light"
              c={data.styles?.primaryColor ? data.styles.primaryColor : "teal"}
              size={inputSize === "md" ? 60 : 55}
              radius="xl"
            >
              {icon}
            </ThemeIcon>
          </Center>
          <Stack spacing={0} mt="sm" mb="lg">
            <Text fz={inputSize === "md" ? 24 : 20} fw={700}>
              {title}
            </Text>
            <Text c="dimmed" fz={inputSize}>
              {subtitle}
            </Text>
          </Stack>
        </Stack>
        
        <Stack spacing={10}>
          {Object.keys(groupedFields.grouped).map((group) => (
            <MantineGroup key={group} spacing="md" noWrap grow>
              {groupedFields.grouped[group].map((field) => {
                if (field.type === "date") {
                  return (
                    <DateOfBirthSelect
                      key={field.name}
                      onDobChange={handleDobChange}
                      {...form.getInputProps(field.name)}
                    />
                  );
                } else if (field.type === "address") {
                  return (
                    <AddressInput
                      key={field.name}
                      onAddressChange={handleAddressChange}
                      value={{
                        addr: form.values.addr,
                        city: form.values.city,
                        state: form.values.state,
                        zip: form.values.zip,
                      }}
                      {...form.getInputProps(field.name)}
                    />
                  );
                } else {
                  return (
                    <FloatingLabelInput
                      key={field.name}
                      size="lg"
                      label={field.label}
                      isPassword={field.isPassword}
                      isMaskedInput={field.isMaskedInput}
                      {...form.getInputProps(field.name)}
                    />
                  );
                }
              })}
            </MantineGroup>
          ))}
          {groupedFields.ungrouped.map((field) => {
            if (field.type === "date") {
              return (
                <DateOfBirthSelect
                  key={field.name}
                  onDobChange={handleDobChange}
                  {...form.getInputProps(field.name)}
                />
              );
            } else if (field.type === "address") {
              return (
                <AddressInput
                  key={field.name}
                  onAddressChange={handleAddressChange}
                  value={{
                    addr: form.values.addr,
                    city: form.values.city,
                    state: form.values.state,
                    zip: form.values.zip,
                  }}
                  {...form.getInputProps(field.name)}
                />
              );
            } else {
              return (
                <FloatingLabelInput
                  key={field.name}
                  size="lg"
                  label={field.label}
                  isPassword={field.isPassword}
                  isMaskedInput={field.isMaskedInput}
                  {...form.getInputProps(field.name)}
                />
              );
            }
          })}
        </Stack>
      </Box>
      <Box pb="sm">
        <Button 
          mt="xl" 
          fullWidth 
          type="submit" 
          size="lg"
        >
          {buttonText}
        </Button>
      </Box>
    </form>
  );
};

export default DataCollectionForm;
