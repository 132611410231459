// src/RootComponent.js
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import { LoadingOverlay, Box, Text, Center } from "@mantine/core";
import App from "./App";
import { DataProvider } from "./context/DataContext";
import NotFound from "./pages/not-found";
import SubRoutes from "./routes";
import {
  FpjsProvider,
  FingerprintJSPro,
} from "@fingerprintjs/fingerprintjs-pro-react";
import * as Sentry from "@sentry/react"; // Import Sentry

// Sentry is currently disabled
// To re-enable, uncomment the Sentry initialization below and the SentryRoutes variable
/*
Sentry.init({
  dsn: "https://e5bbbf0db439485e92518f5484e1baae@o4504792099258368.ingest.us.sentry.io/4504792101158912",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.breadcrumbsIntegration({
      console: true,
      dom: true,
      fetch: true,
      history: true,
      xhr: true,
    }),
    Sentry.replayIntegration(),
    Sentry.captureConsoleIntegration(),
  ],
  tracesSampleRate: 1.0, // Adjust based on your need
  tracePropagationTargets: [
    /^https:\/\/fe\.dcams\.app/, // Match fe.dcams.app
    /^https:\/\/vx-fe\.dcams\.app/, // Match vx-fe.dcams.app
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
*/

// const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

// Function to get request_id from session_id
async function getRequestIdFromSession(session_id) {
  const response = await fetch(
    process.env.REACT_APP_BACKEND_URL + '/v3/stable/api/frontend/exchangeSessionForToken',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ session_id }) // Payload with the session_id
    }
  )

  if (!response.ok) {
    throw new Error(`Error exchanging session: ${response.statusText}`)
  }

  // Sample response: { "request_id": "1f9dfa59-54fa-4067-9a29-26265342b2f2" }
  const result = await response.json()
  return result.request_id
}

const AppWithData = () => {
  const { request_id } = useParams();
  const location = useLocation();
  const [sessionData, setSessionData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Check fingerprint param directly in the render function to avoid timing issues
  const urlParams = new URLSearchParams(location.search);
  const fingerprintParam = urlParams.get('fingerprint');
  const shouldDisableFingerprint = fingerprintParam === 'f';
  
  // Sentry tag setting is currently disabled
  /*
  useEffect(() => {
    if (request_id) {
      // Set request_id as a tag in Sentry
      Sentry.setTag("request_id", request_id);
    }
  }, [request_id]);
  */

  // Check for frontend query param
  const frontendParam = urlParams.get('frontend');
  
  // Determine if we should use VX backend based on hostname or query param
  const useVxBackend = window.location.hostname === "vx-fe.dcams.app" || frontendParam === "vx-fe";
  
  const getPrimaryUrl = useVxBackend
    ? "https://vx.dcams.app/frontend/session/get_session"
    : `${process.env.REACT_APP_BACKEND_URL}/v3/stable/api/frontend/getUrl`;

  const getSecondaryUrl = !useVxBackend
    ? "https://vx.dcams.app/frontend/session/get_session"
    : `${process.env.REACT_APP_BACKEND_URL}/v3/stable/api/frontend/getUrl`;

  const fetchAndCheck = async (url, request_id) => {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ request_id }),
    });

    if (!response.ok) {
      throw new Error(`Error with request to ${url}: ${response.statusText}`);
    }

    const result = await response.json();

    if (!result.data) {
      throw new Error(`No valid data returned from ${url}`);
    }

    return result;
  };

  // Fetch the session data first
  useEffect(() => {
    const fetchData = async () => {
      if (!request_id || request_id === 'upload') {
        setIsLoading(false);
        return;
      }

      let localRequestId = request_id;

      // Handle special cases for request_id
      if (localRequestId === 'clear' || localRequestId === 'oneid' || localRequestId === 'digilocker') {
        localRequestId = urlParams.get('state');
      } else if (localRequestId === 'mdl') {
        const session_id = urlParams.get('sessionId');
        if (!session_id) {
          setError('No session_id in query params.');
          setIsLoading(false);
          return;
        }
        try {
          localRequestId = await getRequestIdFromSession(session_id);
        } catch (err) {
          setError('Could not exchange session for token');
          setIsLoading(false);
          return;
        }
      }

      try {
        let result = await fetchAndCheck(getPrimaryUrl, localRequestId);

        if (!result) {
          console.warn(`Primary URL failed, trying secondary URL: ${getSecondaryUrl}`);
          result = await fetchAndCheck(getSecondaryUrl, localRequestId);
        }

        setSessionData(result);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to load session data");
        setIsLoading(false);
      }
    };

    fetchData();
  }, [request_id]);

  if (isLoading) {
    return (
      <Box sx={{ position: 'relative', height: '100vh' }}>
        <LoadingOverlay visible={true} overlayBlur={2} />
      </Box>
    );
  }

  if (error) {
    return (
      <Center style={{ height: '100vh', flexDirection: 'column' }}>
        <Text color="red" size="xl" mb={10}>❌</Text>
        <Text>{error}</Text>
      </Center>
    );
  }

  // Determine if fingerprinting should be disabled based on URL param or settings
  // Default to true if is_fingerprint_active is not explicitly set to false
  const isFingerPrintActive = sessionData?.data?.settings?.is_fingerprint_active !== false;
  const fingerprintDisabled = shouldDisableFingerprint || !isFingerPrintActive;

  // Skip FpjsProvider when disabled via URL param or settings
  if (fingerprintDisabled) {
    return (
      <DataProvider 
        request_id={request_id} 
        fingerprintDisabled={true}
        initialSessionData={sessionData}
      >
        <App routes={<SubRoutes />} />
      </DataProvider>
    );
  }

  // Regular flow with fingerprinting
  return (
    <FpjsProvider
      loadOptions={{
        apiKey: "U1OoU5FUoWuUW1gJvyL3",
        endpoint: [
          "https://fingerprint.dcams.app",
          FingerprintJSPro.defaultEndpoint,
        ],
        scriptUrlPattern: [
          "https://fingerprint.dcams.app/web/v<version>/<apiKey>/loader_v<loaderVersion>.js",
          FingerprintJSPro.defaultScriptUrlPattern,
        ],
      }}
    >
      <DataProvider 
        request_id={request_id} 
        fingerprintDisabled={false}
        initialSessionData={sessionData}
      >
        <App routes={<SubRoutes />} />
      </DataProvider>
    </FpjsProvider>
  );
};

const RootComponent = () => {
  return (
    <Router>
      {/* Sentry error boundary is currently disabled
      <Sentry.ErrorBoundary fallback={<div>An error has occurred.</div>}>
      */}
        <Routes>
          <Route path="/:request_id/*" element={<AppWithData />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      {/* </Sentry.ErrorBoundary> */}
    </Router>
  );
};

export default RootComponent;
