import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Paper,
  Group,
  Center,
  Stack,
  Text,
  Container,
  rem,
  LoadingOverlay,
  Button,
  ThemeIcon,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import Lottie from "react-lottie-player";
import Microblink from "./microblink/capture/Index";
import VeratadCapture from "./veratad/capture";
import FaceCapture from "../face-capture";
import { useParams } from "react-router-dom";
import { DataContext } from "../../context/DataContext";
import InstructionsCarousel from "./instructions-carousel";
import { Carousel } from "@mantine/carousel";
import Mitek from "./mitek/capture";
import { isMobile } from "react-device-detect";
import Handoff from "../handoff";
import useStatusCheck from "../../hooks/useStatusCheck";
import { IconSunHigh, IconId, IconCapture } from "@tabler/icons-react";
import CenterLoadingOverlay from "../../components/center-loading-overlay";
import IDScan from "../../assets/id-scan-loading.json";
import VerificationLoadingScreen from "../../components/verification-loading-screen";
export default function Dcams({ isModular = false }) {
  const { sdk } = useParams();
  const { data, settings, setSessionState, text } = useContext(DataContext);
  const [isSDKActive, setIsSDKActive] = useState(false);
  const [isSDKComplete, setIsSDKComplete] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [getFace, setGetFace] = useState(false);
  const [gotFace, setGotFace] = useState(false);
  const [status, setStatus] = useState("");
  const [onMobile, setOnMobile] = useState(isMobile);

  const cp = isModular ? "dcams/modular" : "dcams/mitek";
  const isSmallMobile = useMediaQuery("(max-width: 395px)");

  useStatusCheck(isSDKComplete, false, cp);

  useEffect(() => {
    if (isSDKComplete) {
      if (settings.journey.settings?.isSelfie && !gotFace) {
        setIsUploading(false);
        setIsSDKComplete(false);
        setGetFace(true);
        setLoading(false);
        return;
      }

      setLoading(true);
      data.settings = settings;
      data.type = "SDK_Scan";
      fetch("https://vx.dcams.app/frontend/dcams/process", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then(function (res) {
          return res.json();
        })
        .then(function (result) {
          setLoading(false);
        })
        .catch(function () {
          alert("Something Went Wrong! Please try again.");
        });
    }
  }, [isSDKComplete, gotFace]);

  const handleFaceCaptureComplete = () => {
    setIsSDKComplete(true);
    setGotFace(true);
  };

  if (isSDKComplete) {
    return (
      <CenterLoadingOverlay
        content={
          <VerificationLoadingScreen type="documentVerificationLongDuration" />
        }
      />
    );
  }

  const handleStart = () => {
    setIsSDKActive(true);
  };

  if (loading || isUploading) {
    return <LoadingOverlay visible />;
  }

  // We now use the onMobile state defined above
  
  if (!onMobile) {
    // Check if shouldShowContinueOnDesktop is explicitly set to true in settings
    // Default to false if setting doesn't exist
    const should_show_continue_on_desktop = settings?.shouldShowContinueOnDesktop === true;
    
    // Create the HandoffComponent with or without the continue button based on settings
    const HandoffComponent = should_show_continue_on_desktop ? (
      <>
        <Handoff nextPath="dcams/mitek" />
        <Button onClick={() => setOnMobile(true)} mt='sm' fullWidth variant="subtle">
          {text.qr?.troublePrimaryButton || "Continue on this device"}
        </Button>
      </>
    ) : <Handoff nextPath="dcams/mitek" />;
    
    // Return the handoff component
    return HandoffComponent;
  }

  const instructions = [
    { Icon: IconSunHigh, instruction: text.dcams.instructions[0] },
    { Icon: IconId, instruction: text.dcams.instructions[1] },
    { Icon: IconCapture, instruction: text.dcams.instructions[2] },
  ];

  return getFace ? (
    <FaceCapture onCompletion={handleFaceCaptureComplete} />
  ) : (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between", // This will push the button to the bottom
          height: "100%", // This should take the full height of its parent
        }}
      >
        <Box>
          <Stack align="center" spacing="md">
            <ThemeIcon
              variant="light"
              c={data.styles?.primaryColor ? data.styles.primaryColor : "teal"}
              size={85}
              radius={"xl"}
            >
              <IconId size={70} stroke={1} />
            </ThemeIcon>

            <Text fz={30} fw={700} align="center">
              {text.dcams.title}
            </Text>
            <Text align="center">{text.dcams.subTitle}</Text>
            <Paper
              withBorder
              p="md"
              style={{ backgroundColor: "#fafafa", width: "100%" }}
            >
              <Stack spacing={isSmallMobile ? "xs" : "lg"}>
                {instructions.map(({ Icon, instruction }, index) => (
                  <Group
                    key={index}
                    spacing={isSmallMobile ? "xs" : "sm"} // Adjust spacing for very small screens
                    noWrap
                  >
                    {/* Render the icon dynamically with the size prop */}
                    <Icon size={isSmallMobile ? 16 : 30} />

                    {/* Text with responsive font size */}
                    <Text
                      fz={isSmallMobile ? "12px" : "20px"} // Smaller font size for very small screens
                      
                    >
                      {instruction}
                    </Text>
                  </Group>
                ))}
              </Stack>
            </Paper>
          </Stack>
        </Box>
        <Box>
          <Text fz="sm" color="dimmed" align="center" pb={15}>
            {text.dcams.note}
          </Text>

          <Microblink
            onUpload={() => setIsUploading(true)}
            onComplete={() => setIsSDKComplete(true)}
            onStart={handleStart}
          />
        </Box>
      </Box>
    </>
  );
}
