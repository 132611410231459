import React, { useState, useContext, useEffect } from "react";
import YotiFaceCapture from "./yoti/capture"; // Adjust the import path as needed
import { DataContext } from "../../../context/DataContext";
import Handoff from "../../handoff";
import { isMobile } from "react-device-detect";
import {
  Text,
  Button,
  LoadingOverlay,
  Center,
  Stack,
  Group,
  Paper,
  Box,
  ThemeIcon,
} from "@mantine/core";
import {
  IconChefHatOff,
  IconEyeglass2,
  IconFaceId,
  IconSunHigh,
} from "@tabler/icons-react";
import useStatusCheck from "../../../hooks/useStatusCheck";
const AgeEstimation = () => {
  const { settings, data, dispatch, text } = useContext(DataContext);
  const [isReady, setIsReady] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);
  const [status, setStatus] = useState("NEW");
  const [onMobile, setOnMobile] = useState(isMobile);

  useStatusCheck(isComplete, false, "agematch/estimate");

  const handleReady = () => {
    setIsReady(true);
    // Additional code if needed when the component is ready
  };

  const handleLoading = () => {
    setIsLoading(true);
    // Additional code if needed when the image is being processed
  };

  const handleComplete = () => {
    const request_id = settings.request_id;
    data.request_id = request_id;
    data.type = "SDK_SCAN";
    data.country = settings.country;
    if (!("front_ext" in data)) {
      data.front_ext = "jpeg";
      data.back_ext = "jpeg";
    }
    setIsComplete(true);
    fetch(
      process.env.REACT_APP_BACKEND_URL +
        "/v3/stable/api/frontend/estimate/process.php",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {})
      .catch(function () {
        alert("Something Went Wrong! Please try again.");
      });
    // Additional code if needed when the image processing is complete
  };

  const handleError = () => {
    setHasError(true);
    dispatch({
      type: "SET_IS_CURRENT_COMPONENT_COMPLETE",
      payload: true,
    });
    dispatch({ type: "SET_STATUS", payload: "PENDING" });
    dispatch({ type: "TRIGGER_NAVIGATION", payload: true });

    // Additional code if needed in case of an error
  };

  const handleStart = () => {
    setHasStarted(true);
    // Additional code if needed in case of an error
  };

  const intro = (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between", // This will push the button to the bottom
          height: "100%", // This should take the full height of its parent
        }}
      >
        <Box>
          <Stack align="center" spacing="md">
            <ThemeIcon variant="light" c="teal" size={85} radius={"xl"}>
              <IconFaceId size={70} stroke={1} />
            </ThemeIcon>
            <Text fz={30} fw={700} align="center">
              {text.estimation.title}
            </Text>
          </Stack>
          <Text align="center">{text.estimation.subTitle}</Text>
          <Paper
            withBorder
            m="md"
            p="md"
            style={{ backgroundColor: "#fafafa" }}
          >
            <Stack spacing={"xl"}>
              <Group noWrap>
                <IconSunHigh size={30} />
                <Text fz={18}>{text.estimation.instructions[0]}</Text>
              </Group>
              <Group noWrap>
                <IconChefHatOff size={30} />
                <Text fz={18}>{text.estimation.instructions[1]}</Text>
              </Group>
              <Group noWrap>
                <IconEyeglass2 size={30} />
                <Text fz={18}>{text.estimation.instructions[2]}</Text>
              </Group>
            </Stack>
          </Paper>
        </Box>
        <Box>
          <Text fz="sm" c="dimmed" align="center" pb={15}>
            {text.estimation.note}
          </Text>
          <Button size="lg" onClick={handleStart} fullWidth>
            {text.estimation.button}
          </Button>
        </Box>
      </Box>
    </>
  );

  const should_show_continue_on_desktop = settings?.shouldShowContinueOnDesktop === true;
  const HandoffComponent = should_show_continue_on_desktop ? (
    <>
      <Handoff nextPath="agematch/estimate" />
      <Button onClick={() => setOnMobile(true)} mt='sm' fullWidth variant="subtle">{text.qr.troublePrimaryButton}</Button>
    </>
  ) : <Handoff nextPath="agematch/estimate" />;

  if (isLoading) {
    return <LoadingOverlay visible />;
  }

  if (hasStarted) {
    console.log("hasStarted", hasStarted);
    console.log("isReady", isReady);
    console.log("isLoading", isLoading);
    console.log("isComplete", isComplete);
    console.log("hasError", hasError);
    console.log("status", status);
    console.log("onMobile", onMobile);
    console.log("data", data);
    return (
      <YotiFaceCapture
        onReady={handleReady}
        onLoading={handleLoading}
        onComplete={handleComplete}
        onError={handleError}
        language={data.language}
      />
    );
  }

  return <>{!onMobile ? HandoffComponent : intro}</>;
};

export default AgeEstimation;