import React, { useEffect, useRef, useContext, useState } from "react";
import ReactDOM from "react-dom";
import { uploadImageToS3 } from "../../../../../helpers";
import { DataContext } from "../../../../../context/DataContext";
import { isMobile } from "react-device-detect";

const YotiFaceCapture = ({
  onReady,
  onLoading,
  onComplete,
  onError,
  language,
}) => {
  const { settings } = useContext(DataContext);
  const rootRef = useRef(null);
  let fcm = null;
  const errorTimerRef = useRef(null);
  const [paddingTop, setPaddingTop] = useState("100px");

  // Calculate optimal padding based on viewport height
  useEffect(() => {
    if (!isMobile) {
      // For desktop: dynamically calculate padding to center component vertically
      const calculatePadding = () => {
        const viewportHeight = window.innerHeight;
        // Using 600px as approximate height of the Yoti face capture component
        const componentHeight = 600; 
        
        // For larger screens, use a more balanced calculation
        // For smaller screens, ensure there's still adequate space
        let calculatedPadding;
        
        // Increase padding for all screen sizes
        if (viewportHeight < 700) {
          // For smaller screens, use more padding
          calculatedPadding = Math.max(67, Math.round(viewportHeight * 0.15));
        } else if (viewportHeight < 900) {
          // For medium screens, use more padding
          calculatedPadding = Math.max(67, Math.round(viewportHeight * 0.2));
        } else {
          // For larger screens, use even more padding
          calculatedPadding = Math.max(67, Math.round((viewportHeight - componentHeight) / 2.5));
        }
        
        setPaddingTop(`${calculatedPadding}px`);
        console.log(`Set padding to ${calculatedPadding}px for viewport height ${viewportHeight}px`);
      };
      
      calculatePadding();
      
      // Recalculate on resize
      window.addEventListener('resize', calculatePadding);
      return () => window.removeEventListener('resize', calculatePadding);
    }
  }, []);
  
  useEffect(() => {
    console.log("YotiFaceCapture component mounted");
    // Small delay to ensure DOM is ready after portal render
    const initTimer = setTimeout(() => {
      if (window.Yoti && window.Yoti.FaceCaptureModule) {
        console.log("Initializing Yoti face capture");
        onReady();
        const props = {
          faceCaptureAssetsRootUrl: "/assets/face-capture/",
          manualCaptureFallback: false,
          showInitialGuidance: false,
          showGetHelpButton: false,
          encryptImage: false,
          secure: false,
          clientSdkId: "48c6f969-16be-493b-8d7a-8d6f6d7544cc",
          language: language || 'en',
          onSuccess: async (data) => {
            console.log("Yoti face capture successful");
            clearTimeout(errorTimerRef.current);
            onLoading();
            const base64Response = await fetch(
              `data:image/jpeg;base64,${data.img}`
            );
            const blob = await base64Response.blob();
            const suffix = "selfie";
            const imageKey = `${settings.request_id}_dl_${suffix}.jpeg`;
            try {
              const upload = await uploadImageToS3(imageKey, blob);
              console.log("Image uploaded successfully");
              onComplete();
            } catch (error) {
              console.error("Error uploading image:", error);
              onError();
            }
            unmount();
          },
          onError: (error) => {
            console.error("Yoti face capture error:", error);
            clearTimeout(errorTimerRef.current);
            onError();
            unmount();
          },
        };
        
        try {
          console.log("Rendering Yoti FaceCaptureModule to", rootRef.current);
          fcm = window.Yoti.FaceCaptureModule.render(props, rootRef.current);
          console.log("Yoti FaceCaptureModule rendered successfully");
        } catch (error) {
          console.error("Error rendering Yoti FaceCaptureModule:", error);
          onError();
          return;
        }

        // No timeout - let Yoti component stay loaded until user completes the process
        console.log("Yoti component loaded and will remain active until completed");
      } else {
        console.error("Yoti FaceCaptureModule not found");
        onError();
      }
    }, 100); // Short delay to ensure portal is rendered

    return () => {
      clearTimeout(initTimer);
      if (fcm) {
        console.log("Unmounting Yoti FaceCaptureModule");
        fcm.unmount();
      }
      // No need to clear the timeout as we're not using it anymore
    };
  }, []);

  // This function is only called after successful capture or explicit error
  const unmount = () => {
    if (fcm) {
      onReady(false);
      fcm.unmount();
    }
  };

  // Use React Portal to render the component directly to the document body
  // This completely bypasses any parent container constraints
  // Apply different styles for mobile vs desktop
  const containerStyle = isMobile ? 
    {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: "100vw",
      height: "100vh", 
      zIndex: 9999,
      backgroundColor: "#f5f5f5",
    } : {
      position: "fixed",
      inset: 0,
      paddingTop: paddingTop, // Using dynamically calculated padding
      zIndex: 9999,
      backgroundColor: "rgb(0 0 0)",
    };

  return ReactDOM.createPortal(
    <div
      ref={rootRef}
      id="face-capture-module-root"
      style={containerStyle}
    ></div>,
    document.body // Render directly to body instead of within parent component
  );
};

export default YotiFaceCapture;