import React, { useContext, useEffect } from "react";
import {
  Paper,
  Text,
  Flex,
  Container,
  Group,
  ActionIcon,
  Image,
} from "@mantine/core";
import { useNavigate, useLocation } from "react-router-dom";
import {
  IconAlertCircle,
  IconArrowLeft,
  IconShieldCheck,
  IconX,
} from "@tabler/icons-react";
import { DataContext } from "../context/DataContext";

export default function Navigation({ logo_url }) {
  const context = useContext(DataContext);
  const navigate = useNavigate();
  const isIframe = window !== parent;

  // Only destructure if context is available
  const dispatch = context?.dispatch;
  const settings = context?.settings;
  const currentPath = context?.currentPath;
  
  // Determine if close button should be shown
  // Default behavior: show X if in iframe
  // Override: if isCloseButtonShown exists and is false, don't show even if in iframe
  const shouldHideCloseButton = settings?.isCloseButtonShown === false;

  const handleClose = () => {
    if (dispatch) {
      dispatch({ type: "SET_CLOSED_CLICKED", payload: true });
    }
    window.parent.postMessage("CLOSE", "*");
  };

  const handleBack = () => {
    if (dispatch) {
      dispatch({ type: "SET_SELECTED_COMPONENT", payload: "" });
    }
    navigate(-1);
  };

  return (
    <Group
      position={!logo_url ? "right" : "apart"}
      style={{ height: "30px", minHeight: "30px" }}
    >
      {logo_url && <Image src={logo_url} width={50} />}

      {isIframe && !shouldHideCloseButton && (
        <ActionIcon onClick={handleClose}>
          <IconX stroke={1} size={18} />
        </ActionIcon>
      )}
    </Group>
  );
}
