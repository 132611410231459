import React, { createContext, useReducer, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  LoadingOverlay,
  Box,
  Text,
  Stack,
  ThemeIcon,
  Center,
  Group,
  Loader,
  Button,
} from "@mantine/core";
import {
  IconShieldCheck,
  IconFaceIdError,
  IconExclamationCircle,
} from "@tabler/icons-react";
import ErrorMessage from "../components/error-message";
import Navigation from "../components/navigation";
import CenterLoadingOverlay from "../components/center-loading-overlay";
import {
  useVisitorData,
  FingerprintJSPro,
} from "@fingerprintjs/fingerprintjs-pro-react";
// Creating the context
export const DataContext = createContext();

// Initial state for the reducer
const initialState = {
  data: null,
  settings: null,
  text: null,
  routeSequence: [],
  sessionState: {},
  currentIndex: 0,
  alreadyDone: false,
  triggerNavigation: false,
  status: "PENDING",
  totalRoutes: 0,
  completedRoutes: 0,
  selectedComponent: null,
  modularFailedAtLeastOne: false,
  referrer: "",
  closedClicked: false,
  confirmationNumber: null, // Add this to track the latest confirmation number
  // Add other states as necessary
};

// Reducer function to handle state changes
const dataReducer = (state, action) => {
  switch (action.type) {
    case "SET_DATA":
      return { ...state, data: action.payload };
    case "SET_STATUS":
      return { ...state, status: action.payload };
    case "SET_SELECTED_COMPONENT":
      return { ...state, selectedComponent: action.payload };
    case "SET_CLOSED_CLICKED":
      return { ...state, closedClicked: action.payload };
    case "SET_MODULAR_FAILED_AT_LEAST_ONE":
      return { ...state, modularFailedAtLeastOne: action.payload };
    case "TRIGGER_NAVIGATION":
      return { ...state, triggerNavigation: !state.triggerNavigation }; // Toggle the trigger
    case "SET_IS_CURRENT_COMPONENT_COMPLETE":
      return { ...state, isCurrentComponentComplete: action.payload };
    case "SET_SETTINGS":
      return { ...state, settings: action.payload };
    case "SET_TEXT":
      return { ...state, text: action.payload };
    case "SET_SESSION_STATE":
      return { ...state, sessionState: action.payload };
    case "SET_CURRENT_INDEX":
      return { ...state, currentIndex: action.payload };
    case "SET_ALREADY_DONE":
      return { ...state, alreadyDone: action.payload };
    case "SET_REFERRER":
      return { ...state, referrer: action.payload };
    case "SET_CONFIRMATION_NUMBER":
      return { ...state, confirmationNumber: action.payload };
    case "SET_ROUTE_SEQUENCE":
      return {
        ...state,
        routeSequence: action.payload,
        totalRoutes: action.payload.length, // Set the total number of routes
      };
    case "INCREMENT_COMPLETED_ROUTES":
      return {
        ...state,
        completedRoutes: state.completedRoutes + 1,
      };
    // Add more cases as needed
    default:
      return state;
  }
};

async function getRequestIdFromSession(session_id) {
  const response = await fetch(
    process.env.REACT_APP_BACKEND_URL + '/v3/stable/api/frontend/exchangeSessionForToken',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ session_id }) // Payload with the session_id
    }
  )

  if (!response.ok) {
    throw new Error(`Error exchanging session: ${response.statusText}`)
  }

  // Sample response: { "request_id": "1f9dfa59-54fa-4067-9a29-26265342b2f2" }
  const result = await response.json()
  return result.request_id
}

// DataProvider component
export const DataProvider = ({ children, request_id, fingerprintDisabled = false, initialSessionData = null }) => {
  const [state, dispatch] = useReducer(dataReducer, initialState);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const qr = searchParams.get("qr");
  const nextPath = searchParams.get("nextPath");
  const [fpjs, setFpjs] = useState(fingerprintDisabled ? { visitorId: 'disabled-via-url-param' } : null);
  const [fingerprintSent, setFingerprintSent] = useState(fingerprintDisabled);
  const [errorGettingData, setErrorGettingData] = useState(false);
  const [overload, setOverload] = useState(false);
  const [isBot, setIsABot] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [retryCount, setRetryCount] = useState(0); // Track number of retries
  
  // Store the fingerprint disabled state from props
  const [internalFingerprintDisabled, setInternalFingerprintDisabled] = useState(fingerprintDisabled);

  const { getData } = useVisitorData(
    { extendedResult: false, tag: { request_id: request_id } },
    { immediate: false }
  );

  const checkFpjsData = () => {
    // Check if fingerprinting is disabled from props or if we already have dummy data
    if (internalFingerprintDisabled || fpjs?.visitorId === 'disabled-via-url-param') {
      // Make sure we have the dummy data set
      if (!fpjs) {
        setFpjs({ visitorId: 'disabled-via-url-param' });
      }
      
      // Make sure we marked fingerprint as sent
      if (!fingerprintSent) {
        setFingerprintSent(true);
      }
      
      return;
    }
    
    // Check if settings are available and fingerprinting is disabled in settings
    if (state.settings && state.settings.is_fingerprint_active === false) {
      setFpjs({ visitorId: 'disabled-via-settings' });
      setFingerprintSent(true);
      setInternalFingerprintDisabled(true);
      return;
    }
    
    // Check URL parameters as a backup
    const urlParams = new URLSearchParams(window.location.search);
    const fingerprintParam = urlParams.get('fingerprint');
    
    // If fingerprinting disabled via URL param, set dummy data immediately
    if (fingerprintParam === 'f') {
      setFpjs({ visitorId: 'disabled-via-url-param' });
      setFingerprintSent(true);
      setInternalFingerprintDisabled(true);
      return;
    }

    getData()
      .then((data) => {
        setFpjs(data);
        setOverload(false); // Reset overload if successful
      })
      .catch((error) => {
        if (error.message === FingerprintJSPro.ERROR_RATE_LIMIT) {
          setOverload(true);
        } else {
          setErrorGettingData(true);
        }
      });
  };

  useEffect(() => {
    if (overload) {
      const intervalId = setInterval(() => {
        checkFpjsData();
      }, 5000); // Retry every 3 seconds

      return () => clearInterval(intervalId); // Cleanup on unmount
    }
  }, [overload]);

  // Initial fingerprint check and whenever settings change
  useEffect(() => {
    // Only run if fingerprinting is not already disabled
    if (!internalFingerprintDisabled && !fingerprintSent) {
      checkFpjsData();
    }
  }, [state.settings]); // Run when settings change

  useEffect(() => {
    // Check if fingerprinting is disabled or if we've already sent data
    if (internalFingerprintDisabled || fingerprintSent) {
      return;
    }
    
    // Check if we have the settings and fingerprint is active
    if (request_id && fpjs && state.settings) {
      // Skip if fingerprinting is disabled in settings
      if (state.settings && state.settings.is_fingerprint_active === false) {
        setFingerprintSent(true);
        return;
      }
      
      // Skip sending fingerprint data if it's our dummy data
      if (fpjs.visitorId === 'disabled-via-url-param') {
        setFingerprintSent(true);
        return;
      }
      
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Ensures the server knows you're sending JSON
        },
        body: JSON.stringify({
          request_id: request_id,
          fpjs_data: fpjs,
          site_name: state.settings?.site_name || null,
        }),
      };

      fetch("https://vx.dcams.app/frontend/session/fingerprint", requestOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setFingerprintSent(true);
          setIsABot(data.isBot);
          
          // Handle isBlocked if it exists and is true
          if (data.isBlocked === true) {
            setIsBlocked(true);
            // Send a postMessage to parent window
            window.parent.postMessage("ALREADY VERIFIED", "*");
          }
        })
        .catch((error) => {
          //setErrorGettingData(true);
          console.error("Error storing fingerprint:", error);
        });
    }
  }, [request_id, fpjs, fingerprintSent, state.settings, internalFingerprintDisabled]);

  // Fetch initial data
  const fetchAndCheck = async (url, request_id) => {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ request_id }),
    });

    if (!response.ok) {
      throw new Error(`Error with request to ${url}: ${response.statusText}`);
    }

    const result = await response.json();

    if (!result.data) {
      throw new Error(`No valid data returned from ${url}`);
    }

    return result;
  };

  const handleRouteSequence = (dispatch, result, nextPath) => {
    if (result.data.settings && result.data.settings.journey.route) {
      let updatedRouteSequence = result.data.settings.journey.route.map(
        (route) => ({
          ...route,
          shouldProceed: (sessionState) =>
            sessionState[route.shouldProceedKey] === route.expectedStatus,
        })
      );

      if (nextPath) {
        const nextPathIndex = updatedRouteSequence.findIndex(
          (route) => route.path === nextPath
        );

        if (nextPathIndex > -1) {
          updatedRouteSequence = updatedRouteSequence.filter(
            (_, index) => index >= nextPathIndex
          );
        } else {
          const lastRoute =
            updatedRouteSequence[updatedRouteSequence.length - 1];
          const newRoute = {
            ...lastRoute,
            path: nextPath,
            shouldProceedKey: nextPath,
          };

          updatedRouteSequence.unshift(newRoute);
        }

        if (nextPath === "dcams/mitek") {
          updatedRouteSequence = [updatedRouteSequence[0]];
        }
      }

      dispatch({ type: "SET_ROUTE_SEQUENCE", payload: updatedRouteSequence });
    }
  };

  // Check for frontend query param
  const frontendParam = searchParams.get('frontend');
  
  // Determine if we should use VX backend based on hostname or query param
  const useVxBackend = window.location.hostname === "vx-fe.dcams.app" || frontendParam === "vx-fe";
  
  const getPrimaryUrl = useVxBackend
    ? "https://vx.dcams.app/frontend/session/get_session"
    : `${process.env.REACT_APP_BACKEND_URL}/v3/stable/api/frontend/getUrl`;

  const getSecondaryUrl = !useVxBackend
    ? "https://vx.dcams.app/frontend/session/get_session"
    : `${process.env.REACT_APP_BACKEND_URL}/v3/stable/api/frontend/getUrl`;

  const fetchData = async (request_id, dispatch, nextPath) => {
    try {
      let result = await fetchAndCheck(getPrimaryUrl, request_id);

      if (!result) {
        console.warn(
          `Primary URL failed, trying secondary URL: ${getSecondaryUrl}`
        );
        result = await fetchAndCheck(getSecondaryUrl, request_id);
      }

      if (result) {
        dispatch({ type: "SET_DATA", payload: result.data });
        dispatch({
          type: "SET_ALREADY_DONE",
          payload: nextPath ? false : result.already_done,
        });
        
        // Settings received from the backend
        
        dispatch({ type: "SET_SETTINGS", payload: result.data.settings });
        dispatch({ type: "SET_TEXT", payload: result.data.settings.text });

        handleRouteSequence(dispatch, result, nextPath);
        setErrorGettingData(false);
        setRetryCount(0); // Reset retry count on successful fetch
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setErrorGettingData(true);
    }
  };

  const handleRetry = () => {
    if (retryCount < 1) {
      setRetryCount((prevCount) => prevCount + 1);
      fetchData(request_id, dispatch, nextPath);
    }
  };

  // Process the initialSessionData or fetch data if not provided
useEffect(() => {
  // If we have initialSessionData from the root, use it directly
  if (initialSessionData) {
    dispatch({ type: "SET_DATA", payload: initialSessionData.data });
    dispatch({
      type: "SET_ALREADY_DONE",
      payload: nextPath ? false : initialSessionData.already_done,
    });
    
    dispatch({ type: "SET_SETTINGS", payload: initialSessionData.data.settings });
    dispatch({ type: "SET_TEXT", payload: initialSessionData.data.settings.text });

    if (initialSessionData.data.settings && initialSessionData.data.settings.journey.route) {
      let updatedRouteSequence = initialSessionData.data.settings.journey.route.map(
        (route) => ({
          ...route,
          shouldProceed: (sessionState) =>
            sessionState[route.shouldProceedKey] === route.expectedStatus,
        })
      );

      if (nextPath) {
        const nextPathIndex = updatedRouteSequence.findIndex(
          (route) => route.path === nextPath
        );

        if (nextPathIndex > -1) {
          updatedRouteSequence = updatedRouteSequence.filter(
            (_, index) => index >= nextPathIndex
          );
        } else {
          const lastRoute =
            updatedRouteSequence[updatedRouteSequence.length - 1];
          const newRoute = {
            ...lastRoute,
            path: nextPath,
            shouldProceedKey: nextPath,
          };

          updatedRouteSequence.unshift(newRoute);
        }

        if (nextPath === "dcams/mitek") {
          updatedRouteSequence = [updatedRouteSequence[0]];
        }
      }

      dispatch({ type: "SET_ROUTE_SEQUENCE", payload: updatedRouteSequence });
    }
    
    setErrorGettingData(false);
    return; // Don't continue with the fetch if we already have data
  }
  
  // If we don't have initialSessionData, fallback to the original fetch logic
  const doFetch = async () => {
    let localRequestId = request_id

    // Case A: if request_id is clear / oneid / digilocker, set localRequestId to the 'state' query param
    if (
      localRequestId === 'clear' ||
      localRequestId === 'oneid' ||
      localRequestId === 'digilocker'
    ) {
      localRequestId = searchParams.get('state')
    }
    // Case B: if request_id is "mdl", fetch the real request_id using the session_id query param
    else if (localRequestId === 'mdl') {
      const session_id = searchParams.get('sessionId')
      if (!session_id) {
        console.error('No session_id in query params.')
        return
      }
      try {
        localRequestId = await getRequestIdFromSession(session_id)
      } catch (err) {
        console.error('Could not exchange session for token:', err)
        setErrorGettingData(true)
        return
      }
    }

    // Case C: if we have a valid localRequestId and it's not "upload", fetch the data
    if (localRequestId && localRequestId !== 'upload') {
      try {
        await fetchData(localRequestId, dispatch, nextPath)
        setErrorGettingData(false)
      } catch {
        setErrorGettingData(true)
      }
    }
  }

  doFetch()
}, [initialSessionData, request_id, dispatch, nextPath])

  useEffect(() => {
    if (qr && state.settings) {
      const requestOptions = {
        method: "POST",
        body: JSON.stringify({
          request_id: state.settings.request_id,
          type: "set",
          site_name: state.settings.site_name,
        }),
      };

      fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v3/stable/api/frontend/qrStatus`,
        requestOptions
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          //console.log("QR status update successful:", data);
        })
        .catch((error) => {
          console.error("Error updating QR status:", error);
        });
    } else {
      if (qr) {
        //console.log("QR Code detected but settings are not available yet.");
      }
    }
  }, [qr, state.settings]);

  const navigateToNext = () => {
    // Check if it's the right time to navigate to the next route
    if (state.isCurrentComponentComplete && state.status === "PENDING") {
      dispatch({ type: "INCREMENT_COMPLETED_ROUTES" });
      const currentRoute = state.routeSequence[state.currentIndex];
      const nextIndex = state.currentIndex + 1;
      const nextRoute = state.routeSequence[nextIndex];
      if (state.settings.journey.settings?.isModular) {
        navigate("agematch/modular");
      }
      if (nextRoute) {
        dispatch({ type: "SET_CURRENT_INDEX", payload: nextIndex });
        navigate(nextRoute.path);
      } else {
        //window.top.postMessage("FAIL", "*");
        // Handle the end of the sequence or define a default action if required
      }
    }
  };

  useEffect(() => {
    let event = null;
    let next = state.triggerNavigation ? true : false;
    let error = "NONE";

    if (next) {
      event = "NEXT";
    } else if (state.closedClicked) {
      event = "CLOSED";
    } else if (state.alreadyDone) {
      event = "ERROR";
      error = "ALREADY_DONE";
    } else {
      switch (state.status) {
        case "PASS":
        case "FAIL":
          event = "COMPLETE";
          break;
        default:
          event = null;
      }
    }

    const payload = {
      event: event,
      app: state.routeSequence[state.currentIndex]?.app,
      error: error,
    };
    if (state?.settings?.site_name === "veratad") {
      console.log("post message sent", payload);
      window.parent.postMessage(
        { messageType: "payload", value: payload },
        "*"
      );
    }

    if (state.status === "PASS" || state.status === "FAIL") {
      window.parent.postMessage(state.status, "*");
    }
  }, [state.status, state.triggerNavigation, state.closedClicked]);

  useEffect(() => {
    //console.log("route sequence", state.routeSequence);
    if (!state.triggerNavigation) {
      return;
    }
    navigateToNext();
  }, [state.triggerNavigation, state.routeSequence]); // Only re-run if these specific parts of the state change

  // Context value that will be provided to the consuming components
  const contextValue = {
    ...state, // Spread the state
    dispatch, // Allow dispatch to be used by consuming components
    navigateToNext, // Add other functions and values here
  };

  if (state.alreadyDone || errorGettingData) {
    return (
      <ErrorMessage
        title="Link Expired"
        subtitle={
          <>
            <Text>
              This link is either invalid, expired, or has already been used
            </Text>
            {retryCount <= 0 && (
              <Button
                mt="lg"
                fullWidth
                onClick={handleRetry}
                disabled={retryCount >= 1}
              >
                Try Again
              </Button>
            )}
          </>
        }
      />
    );
  }

  if (overload) {
    return (
      <ErrorMessage
        title="Please Wait"
        subtitle={
          <Group>
            <Loader size={"xs"} />
            <Text>We are getting ready for your verification</Text>
          </Group>
        }
      />
    );
  }

  if (isBot || isBlocked) {
    return (
      <ErrorMessage
        title="You May Not Continue"
        subtitle={<Text>You are unable to continue this process</Text>}
      />
    );
  }

  // No additional useEffect needed - we're now using the prop directly

  if (state.status !== "PENDING") {
    const messages = state.data?.resultMessages ?? {};
    const successTitle =
      messages.success?.verificationSuccessTitle ?? "Verification Success";
    const successSubTitle =
      messages.success?.verificationSuccessSubTitle ??
      "Please return to the other tab";
    const failureTitle =
      messages.failure?.verificationFailureTitle ?? "Verification Failure";
    const failureSubTitle =
      messages.failure?.verificationFailureSubTitle ??
      "Please return to the other tab";

    return (
      <>
        <Navigation logo_url={""} />
        <CenterLoadingOverlay
          content={
            <Center>
              <Stack align="center">
                <ThemeIcon
                  variant="light"
                  color={state.status === "PASS" ? "teal" : "red"}
                  size={95}
                  radius="xl"
                >
                  {state.status === "PASS" ? (
                    <IconShieldCheck size={85} stroke={1.5} />
                  ) : (
                    <IconExclamationCircle size={85} stroke={1.5} />
                  )}
                </ThemeIcon>
                <Text align="center" fz="xl" fw={700}>
                  {state.status === "PASS" ? successTitle : failureTitle}
                </Text>
                <Text align="center" mt="md" fz="md">
                  {state.status === "PASS" ? successSubTitle : failureSubTitle}
                </Text>
              </Stack>
            </Center>
          }
        />
      </>
    );
  }

  //const inIframe = window.self !== window.top;
  
  // Only render children when:
  // 1. We have data from the backend
  // 2. User is not a bot or blocked
  // 3. We have fingerprint data (or fingerprinting is disabled)
  // 4. We have completed the fingerprint check (fingerprintSent is true)
  const shouldRenderChildren = 
    state.data && 
    !isBot && 
    !isBlocked && 
    (fpjs || internalFingerprintDisabled) && 
    fingerprintSent;

  // Ready to render based on the conditions above

  return (
    <DataContext.Provider value={contextValue}>
      {/*<Navigation logo_url={state.data?.logo_url ? state.data.logo_url : ""} />*/}
      {shouldRenderChildren ? children : <LoadingOverlay visible />}
    </DataContext.Provider>
  );
};
