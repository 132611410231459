import { useEffect, useContext, useRef } from "react";
import { DataContext } from "../context/DataContext";
import { notifications } from "@mantine/notifications";
import { IconX } from "@tabler/icons-react";

const useStatusCheck = (triggerCondition, isHandoff, currentPath, customHandler = null) => {
  const interval = 2000; // Reduced to 2 seconds for faster checking
  const { settings, dispatch, routeSequence } = useContext(DataContext);
  const lastConfirmationRef = useRef(null); // Store the last confirmation number
  const isRequestPendingRef = useRef(false); // Track if a request is in progress

  useEffect(() => {
    if (!triggerCondition) return;

    const isLastRoute =
      routeSequence.length > 0 &&
      (currentPath === "dcams/upload" ||
        (currentPath === "idmax" && routeSequence.length === 1) ||
        currentPath === routeSequence[routeSequence.length - 1].path);

    const requestBody = {
      request_id: settings.request_id,
      ...(isHandoff ? {} : { isLastRoute, currentPath }),
    };

    const handleStatusResponse = (result) => {
      if (!result || !result.status) return;

      if (result.status === "NEW") {
        return;
      }
      
      // Extract confirmation number from result if available
      const confirmationNumber = result.confirmation || 
                                (result.meta && result.meta.confirmation) || 
                                null;
      
      // When we first start checking, set the initial confirmation number
      if (lastConfirmationRef.current === null && confirmationNumber) {
        lastConfirmationRef.current = confirmationNumber;
        
        // If this is a COPPA flow, store the confirmation number to global context
        if (currentPath === "idmatch/coppa") {
          dispatch({ 
            type: "SET_CONFIRMATION_NUMBER", 
            payload: confirmationNumber 
          });
        }
      }
      
      // Check if confirmation number has changed (indicating a new verification)
      const confirmationChanged = confirmationNumber && 
                                 lastConfirmationRef.current !== confirmationNumber;
      
      if (confirmationChanged) {
        lastConfirmationRef.current = confirmationNumber;
        
        // Store the new confirmation in the data context for access by other components
        dispatch({ 
          type: "SET_CONFIRMATION_NUMBER", 
          payload: confirmationNumber 
        });
      }
      
      // SPECIAL HANDLING FOR COPPA FLOW
      // If this is the COPPA flow, we need special handling
      if (currentPath === "idmatch/coppa") {
        
        // Pass to custom handler if available
        if (typeof customHandler === 'function') {
          const shouldContinue = customHandler(result, confirmationChanged);
          
          // IMPORTANT OVERRIDE: For COPPA, only handle confirmation changes or terminal states
          // If confirmation hasn't changed and we're waiting for a new attempt, limit polling behavior
          if (shouldContinue === false) {
            // Even if shouldContinue is false, we return a value that won't stop the interval
            // This is critical to ensure polling continues in background while form is shown
            return "form_display"; // Special signal that will be handled below
          }
        }
        
        // For COPPA, never update global state directly - always let form component handle it
        return true; // Always continue polling for COPPA flow
      }
      
      // STANDARD HANDLER FOR NON-COPPA FLOWS
      if (typeof customHandler === 'function') {
        const shouldContinue = customHandler(result, confirmationChanged);
        if (shouldContinue === false) {
          return false; // For non-COPPA flows, respect the handler's decision
        }
        
        // Mark as complete as needed
        if (confirmationChanged || result.status === "PASS") {
          dispatch({ type: "SET_IS_CURRENT_COMPONENT_COMPLETE", payload: true });
        }
      }
      
      // Standard processing for all other cases
      if (result.status === "PASS") {
        dispatch({ type: "SET_STATUS", payload: "PASS" });
        dispatch({ type: "SET_IS_CURRENT_COMPONENT_COMPLETE", payload: true });
      } 
      else if (
        result.status !== "PASS" &&
        result.enhanced_next_document === "false"
      ) {
        if (!isHandoff && currentPath !== "idmax") {
          notifications.show({
            id: "modular-failure",
            autoClose: 4000,
            title: "Whoops! You've failed the previous verification",
            message: settings.journey?.settings?.isModular
              ? "Please select another option below"
              : "Let's try another way",
            color: "red",
            icon: <IconX />,
            className: "error-message",
          });
        }

        if (settings.journey?.settings?.isModular) {
          const updatedRouteSequence = routeSequence.filter(
            (route) => route.path !== currentPath
          );
          dispatch({ type: "SET_SELECTED_COMPONENT", payload: "" });
          dispatch({ type: "SET_MODULAR_FAILED_AT_LEAST_ONE", payload: true });
          dispatch({
            type: "SET_ROUTE_SEQUENCE",
            payload: updatedRouteSequence,
          });
        }

        dispatch({ type: "SET_STATUS", payload: "PENDING" });
        dispatch({ type: "TRIGGER_NAVIGATION", payload: true });
      } else if (
        result.status !== "PASS" &&
        result.detail !== "MORE INFORMATION IS REQUIRED" &&
        result.enhanced_next_document === "true"
      ) {
        dispatch({ type: "SET_STATUS", payload: "FAIL" });
      }

      return true; // Continue polling by default
    };

    const fetchStatusCheck = async () => {
      // Skip this request if another one is already in progress
      if (isRequestPendingRef.current) {
        return;
      }
      
      try {
        // Mark that we're starting a request
        isRequestPendingRef.current = true;
        
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/v3/stable/api/frontend/statusCheck`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(requestBody),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch status");
        }

        const result = await response.json();
        const shouldContinue = handleStatusResponse(result);
        
        // Special case for COPPA flow showing the form
        if (shouldContinue === false && currentPath === "idmatch/coppa") {
          // Stop polling while form is shown - will restart when form is submitted
          clearInterval(intervalId);
        }
        // For all other flows, respect the handler return value
        else if (shouldContinue === false) {
          clearInterval(intervalId);
        }
        // If handling form display but continue polling
        else if (shouldContinue === "form_display") {
          // Stop polling while form is shown
          clearInterval(intervalId);
        }
      } catch (error) {
        // Continue silently without breaking the flow
      } finally {
        // Mark that the request has completed, whether successful or not
        isRequestPendingRef.current = false;
      }
    };

    // Initial fetch immediately
    fetchStatusCheck();
    
    // Then set up the interval for subsequent fetches
    let intervalId = setInterval(fetchStatusCheck, interval);

    return () => {
      clearInterval(intervalId);
      // Reset pending flag when unmounting
      isRequestPendingRef.current = false;
    };
  }, [
    triggerCondition,
    settings.request_id,
    dispatch,
    isHandoff,
    currentPath,
    routeSequence,
  ]);
};

export default useStatusCheck;
