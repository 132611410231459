import React, { useState } from "react";
import {
  TextInput,
  Box,
  Text,
  Tooltip,
  useMantineTheme,
  Input,
  PasswordInput,
  ActionIcon,
  rem,
} from "@mantine/core";
import { IconLock, IconEye, IconEyeOff } from "@tabler/icons-react";

// Custom masked input that looks like password but isn't treated as one by browsers
const MaskedInput = ({
  label,
  value,
  onChange,
  onFocus,
  onBlur,
  placeholder,
  error,
  size = "lg",
  ...props
}) => {
  const [showText, setShowText] = useState(false);
  
  const toggleVisibility = (event) => {
    event.stopPropagation(); // Prevent event bubbling
    setShowText(prevState => !prevState); // Use function form to ensure correct state update
  };
  
  // Add a keypress handler to filter non-numeric input
  const handleKeyPress = (event) => {
    // Allow only numbers (0-9)
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };
  
  // Force -webkit-text-security to work in an inline style
  const maskStyle = !showText ? {
    WebkitTextSecurity: "disc",
    MozTextSecurity: "disc", 
    textSecurity: "disc",
    fontFamily: "text-security-disc" // Fallback for some browsers
  } : {};
  
  // Define a custom class for masked input
  const maskedClass = !showText ? "masked-input" : "";
  
  return (
    <div className="masked-input-container" style={{ position: 'relative' }}>
      <style>
        {`
        .masked-input {
          -webkit-text-security: disc !important;
          -moz-text-security: disc !important;
          text-security: disc !important;
        }
        
        /* Force numeric input styling */
        input[type="tel"]::-webkit-outer-spin-button,
        input[type="tel"]::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        
        input[type="tel"] {
          -moz-appearance: textfield;
        }
        `}
      </style>
      <TextInput
        label={label}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyPress={handleKeyPress} // Add keypress handler to filter input
        placeholder={placeholder}
        error={error}
        size={size}
        // Force specific attributes to ensure they're not overridden by {...props}
        type="tel"
        inputMode="numeric"
        pattern="[0-9]*"
        autoComplete="off"
        data-lpignore="true"
        // Add direct HTML attributes as a last resort
        inputProps={{
          inputmode: "numeric", // HTML attribute (lowercase)
          pattern: "[0-9]*",
          type: "tel"
        }}
        {...props} // Include other props after our forced ones
        classNames={{ input: maskedClass }}
        rightSection={
          <ActionIcon 
            onClick={toggleVisibility} 
            tabIndex={-1}
            style={{ cursor: 'pointer', zIndex: 100 }}
            aria-label={showText ? "Hide text" : "Show text"}
          >
            {showText ? <IconEyeOff size="1.1rem" /> : <IconEye size="1.1rem" />}
          </ActionIcon>
        }
        styles={(theme) => ({
          input: {
            fontSize: "18px",
            paddingTop: "15px",
            ...maskStyle // Apply inline mask style
          },
          rightSection: {
            width: rem(42),
            display: 'flex',
            justifyContent: 'center',
            pointerEvents: 'auto', // Ensure clicks work
            cursor: 'pointer',
            zIndex: 10, // Make sure it's clickable
          },
          ...(props.styles && props.styles(theme))
        })}
      />
    </div>
  );
};

const FloatingLabelInput = ({
  label,
  tooltip,
  onChange,
  value: propValue,
  customOnChange,
  component,
  isPassword = false,
  isMaskedInput = false, // New prop for sensitive data that shouldn't be saved by browsers
  ...props
}) => {
  const [focused, setFocused] = useState(false);
  const theme = useMantineTheme();

  // Determine if the label should float
  const floating = focused || (propValue && propValue.length > 0);

  const handleChange = (event) => {
    // Run custom logic if provided
    if (customOnChange) {
      customOnChange(event);
    } else {
      // Otherwise, just use the provided onChange
      onChange && onChange(event);
    }
  };

  // Choose the right input component
  let InputComponent;
  if (isMaskedInput) {
    // Use our custom masked input for sensitive data
    InputComponent = MaskedInput;
  } else if (isPassword) {
    // Use regular password input for actual passwords
    InputComponent = PasswordInput;
  } else {
    // Default to regular text input
    InputComponent = TextInput;
  }

  const InputComponentReturn = (
    <InputComponent
      {...props}
      size={"lg"}
      value={propValue}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      onChange={handleChange}
      label={label}
      placeholder={floating ? props.placeholder : ""}
      autoComplete="off"
      data-lpignore="true"
      styles={(theme) => ({
        input: {
          fontSize: "18px",
          paddingTop: "15px",
        },
        innerInput: {
          fontSize: "18px",
          paddingTop: "15px",
        },
        label: {
          position: "absolute",
          pointerEvents: "none",
          fontSize: "11px",
          paddingLeft: "10px",
          paddingTop: "5px",
          zIndex: "1",
          color: "#868e96",
        },
        root: {
          position: "relative",
        },
        error: {
          fontSize: "12px",
        },
      })}
    />
  );

  return InputComponentReturn;
};

export default FloatingLabelInput;
